/**
 * Ems1-specific variables
 *
 * Most of the variables are defined in <megaton-dir>/Ivelum.Shared/Tone.Web/
 * (the file imported below). Here are only the variables that need to be
 * overriden
 *
 * !! DON'T USE the "!default" keyword here
 */

// Theme color types
// Grays. Differ from the Style Guides, Approved here:
// https://praetoriangroup.atlassian.net/browse/PGH-228
$gray-dark: #404040;
// TODO: CROSS-1352 since #b1c2ca is too pale for text
$gray-medium: #878787;
$gray-light: #eff0f1;

// colors

/* stylelint-disable */
// TODO: Peter specified this one: 091f37 here: https://praetoriangroup.atlassian.net/browse/FC-928?focusedCommentId=198994&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-198994
/* stylelint-enable */
$blue-dark: #092038;
$blue-dark-2: #0d2e4f;
$blue-dark-3: #26313b;
$blue-header: #143659;
$blue-bright: #0093d9;
$blue-light: #b7c6d0;

$blue-link: #2b5f94;
$blue-bright-hover: #cc001e;

$blue-border-2: #385068;

$yellow: #ffd11b;

$red-bright-hover: #a60a0b;

$red-link: #971b23;
$red-dark: #6a0707;

// Specific elements colors

// Global color for gray borders, hr-s, etc.
$border-color: $gray-medium;

$image-bg: #081f36;

// Global textual link color.
// Using a color different from the Style Guide because with the latter links
// don't stand out in text blocks
$link-color: #0074c9;
$link-hover-color: $blue-bright;

// Special links (like .Link; .List--inline .List-link, etc).
$link-special-color: $link-color;
$link-special-hover-color: $blue-bright;

// Darker links (E.g., counters in Top-5)
$link-dark-color: $blue-link;

/* Components
 ======================================================================= */

// Box
$box-themed-bg: $blue-link;
$box-themed-color: $white;

// Icon
$icon-highlight-color: $blue-bright;

// variables for cub widget
$cub-heading-bg: $blue-dark-3;
$cub-menu-color: $white;
$cub-menu-hover-color: $yellow;
$cub-menu-bg: $blue-bright;
$cub-error-color: $link-hover-color;
$cub-info-bg: $gray-medium;
$cub-error-bg: $red-bright-hover;

// buttons
$btn-bg: $blue-dark-2;
$btn-bg-hover: $blue-link;
$btn-icon-bg: $blue-link;
$btn-icon-bg-hover: $blue-bright;
// If buttons need a border when against a block with background
$btn-on-bg-border: $blue-link;
$btn-on-bg-border-hover: $blue-bright;

// Heading
$heading-sm-color: #091f37;

// Pagination
$pagination-bg-active: $blue-link;
$pagination-border-active: $blue-dark-2;

// TitleBar
$titlebar-bg: $blue-dark-3;
$titlebar-icon-bg: $blue-link;
$titlebar-hover-bg: $blue-link;
$titlebar-hover-icon-bg: $blue-bright;
$titlebar-is-include-badge-styles: false;

// "Editor's note"
$callout-ribbon-bg: $blue-link;
$callout-ribbon-back-bg: $blue-header;

// Modal
$modal-bg: $blue-dark-3;
$modal-icon-bg: $blue-link;
$modal-hover-icon-bg: $blue-bright;
// -warning- variables are used on the Modal-title--warning
$modal-warning-bg: $blue-dark;
$modal-warning-icon-bg: $link-hover-color;
$modal-warning-hover-icon-bg: $blue-link;

// Table
// <th>
$table-head-bg: $blue-link;
$table-head-color: $white;
// <tr>
// highlighted
$table-row-highlight-bg: $blue-dark-2;
$table-row-highlight-color: $white;

// Forms
$form-focus-color: $blue-link;
$form-themed-bg: $blue-dark-3;
$form-themed-input-borders: #003c86; // TODO merge with Grants where this one is a variable
// Hard-coding; maybe unify with #143659 if I can change the BG image there
$form-subscription-bg: #143454;

// 500.html
$page500-h1-color: $blue-link;

// Logo
// $footer-logo-width: 126px;

// Footer
$footer-top-bg: $blue-dark;
$footer-bottom-bg: $blue-header;

$footer-color: $blue-light;
$footer-hover-color: $yellow;

$footer-menu-color: $white;
$footer-menu-hover-color: $yellow;
$footer-menu-margin-lg: 17px;
$footer-menu-border-sm: $blue-border-2;
$footer-menu-bg-sm: $blue-header;

$footer-menu-color-mobile: $footer-menu-color;
$footer-menu-active-color-mobile: $footer-menu-hover-color;
$footer-menu-hover-color-mobile: $footer-menu-hover-color;

// Header
$header-new-bg: $blue-dark;
$header-new-top-ad-bg: $blue-dark;
$header-new-logo-bg: $blue-link;
$header-new-usermenu-bg: $blue-link;
$header-new-cub-menu-hover-color: $yellow;
$header-new-top-link-hover-color: $yellow;
$header-new-bottom-bg-theme-image-opacity-option: 0.4;

$header-rebrand-logo-width: 114px;

// News Line
$news-line-color: $blue-link;

// Main Menu
$menu-new-item-color: #f0f2f3;
$menu-new-item-hover-color: $yellow;

$menu-submenus-bg: $blue-dark-2;
$menu-submenu-bg: $blue-link;
$menu-submenu-border-color: $blue-link;

$menu-submenu-link-l1-hover-bg: $blue-link;
$menu-submenu-link-l2-hover-bg: $yellow;

$header-menu-toggle-bg: none;
$header-menu-toggle-active-color: #231f20;
$header-menu-toggle-active-bg: $yellow;

// Main Menu for Tablet Screens
$menu-submenu-bg-tablet: $blue-link;
$menu-submenu-brd-color-tablet: $blue-header;

$menu-item-bg-tablet-hover: $blue-bright;
$menu-item-brd-color-tablet: $blue-header;

// Headline
// 1) Special Report, Member Update
$headline-bg: $blue-bright;
$headline-title-bg: $blue-dark;
$headline-btn-bg-hover: $btn-bg-hover;

// 2) Breaking News
$headline-bg-danger: $blue-bright-hover;
$headline-title-bg-danger: $red-link;
$headline-btn-bg-hover-danger: $red-dark;
